@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select, button, textarea {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  color: #333333;
  background: transparent;
}

html {
  font-size: 62.5%;
}

body {
  overflow-x: auto;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-feature-settings: "palt";
}

@media screen and (max-width: 768px) {
  body {
    letter-spacing: 0;
  }
}

a {
  color: #333333;
  text-decoration: none;
  overflow-wrap: break-word;
  transition: 0.3s ease-out;
}

a:visited {
  color: #333333;
}

a:hover {
  color: #333333;
}

a:active {
  color: #333333;
}

a[href*="tel:"] {
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 960px) {
  a[href*="tel:"] {
    pointer-events: auto;
    cursor: pointer;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

img:not(.no-fit) {
  width: 100%;
}

@media screen and (min-width: 1281px) {
  .view-xl {
    display: none !important;
  }
}

@media screen and (max-width: 1280px) {
  .none-xl {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .view-l {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .none-l {
    display: none !important;
  }
}

@media screen and (min-width: 961px) {
  .view-m {
    display: none !important;
  }
}

@media screen and (max-width: 960px) {
  .none-m {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .view-s {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .none-s {
    display: none !important;
  }
}

@media screen and (min-width: 521px) {
  .view-xs {
    display: none !important;
  }
}

@media screen and (max-width: 520px) {
  .none-xs {
    display: none !important;
  }
}

.wrap {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 1280px) {
  .wrap {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .wrap {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .wrap {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.inner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 1280px) {
  .inner {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .inner {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.mb-sect {
  margin-bottom: calc(8px * 10);
}

@media screen and (max-width: 960px) {
  .mb-sect {
    margin-bottom: calc((8px * 10) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .mb-sect {
    margin-bottom: calc((8px * 10) * 0.5);
  }
}

.mb-subsect {
  margin-bottom: calc(8px * 6);
}

@media screen and (max-width: 960px) {
  .mb-subsect {
    margin-bottom: calc((8px * 6) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .mb-subsect {
    margin-bottom: calc((8px * 6) * 0.5);
  }
}

.mr-x1 {
  margin-right: 8px;
}

@media screen and (max-width: 960px) {
  .mr-x1 {
    margin-right: 6px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x1 {
    margin-right: 4px;
  }
}

.mb-x1 {
  margin-bottom: 8px;
}

@media screen and (max-width: 960px) {
  .mb-x1 {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x1 {
    margin-bottom: 4px;
  }
}

.ml-x1 {
  margin-left: 8px;
}

@media screen and (max-width: 960px) {
  .ml-x1 {
    margin-left: 6px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x1 {
    margin-left: 4px;
  }
}

.pt-x1 {
  padding-top: 8px;
}

@media screen and (max-width: 960px) {
  .pt-x1 {
    padding-top: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x1 {
    padding-top: 4px;
  }
}

.pr-x1 {
  padding-right: 8px;
}

@media screen and (max-width: 960px) {
  .pr-x1 {
    padding-right: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x1 {
    padding-right: 4px;
  }
}

.pb-x1 {
  padding-bottom: 8px;
}

@media screen and (max-width: 960px) {
  .pb-x1 {
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x1 {
    padding-bottom: 4px;
  }
}

.pl-x1 {
  padding-left: 8px;
}

@media screen and (max-width: 960px) {
  .pl-x1 {
    padding-left: 6px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x1 {
    padding-left: 4px;
  }
}

.mr-x2 {
  margin-right: 16px;
}

@media screen and (max-width: 960px) {
  .mr-x2 {
    margin-right: 12px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x2 {
    margin-right: 8px;
  }
}

.mb-x2 {
  margin-bottom: 16px;
}

@media screen and (max-width: 960px) {
  .mb-x2 {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x2 {
    margin-bottom: 8px;
  }
}

.ml-x2 {
  margin-left: 16px;
}

@media screen and (max-width: 960px) {
  .ml-x2 {
    margin-left: 12px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x2 {
    margin-left: 8px;
  }
}

.pt-x2 {
  padding-top: 16px;
}

@media screen and (max-width: 960px) {
  .pt-x2 {
    padding-top: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x2 {
    padding-top: 8px;
  }
}

.pr-x2 {
  padding-right: 16px;
}

@media screen and (max-width: 960px) {
  .pr-x2 {
    padding-right: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x2 {
    padding-right: 8px;
  }
}

.pb-x2 {
  padding-bottom: 16px;
}

@media screen and (max-width: 960px) {
  .pb-x2 {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x2 {
    padding-bottom: 8px;
  }
}

.pl-x2 {
  padding-left: 16px;
}

@media screen and (max-width: 960px) {
  .pl-x2 {
    padding-left: 12px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x2 {
    padding-left: 8px;
  }
}

.mr-x3 {
  margin-right: 24px;
}

@media screen and (max-width: 960px) {
  .mr-x3 {
    margin-right: 18px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x3 {
    margin-right: 12px;
  }
}

.mb-x3 {
  margin-bottom: 24px;
}

@media screen and (max-width: 960px) {
  .mb-x3 {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x3 {
    margin-bottom: 12px;
  }
}

.ml-x3 {
  margin-left: 24px;
}

@media screen and (max-width: 960px) {
  .ml-x3 {
    margin-left: 18px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x3 {
    margin-left: 12px;
  }
}

.pt-x3 {
  padding-top: 24px;
}

@media screen and (max-width: 960px) {
  .pt-x3 {
    padding-top: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x3 {
    padding-top: 12px;
  }
}

.pr-x3 {
  padding-right: 24px;
}

@media screen and (max-width: 960px) {
  .pr-x3 {
    padding-right: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x3 {
    padding-right: 12px;
  }
}

.pb-x3 {
  padding-bottom: 24px;
}

@media screen and (max-width: 960px) {
  .pb-x3 {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x3 {
    padding-bottom: 12px;
  }
}

.pl-x3 {
  padding-left: 24px;
}

@media screen and (max-width: 960px) {
  .pl-x3 {
    padding-left: 18px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x3 {
    padding-left: 12px;
  }
}

.mr-x4 {
  margin-right: 32px;
}

@media screen and (max-width: 960px) {
  .mr-x4 {
    margin-right: 24px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x4 {
    margin-right: 16px;
  }
}

.mb-x4 {
  margin-bottom: 32px;
}

@media screen and (max-width: 960px) {
  .mb-x4 {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x4 {
    margin-bottom: 16px;
  }
}

.ml-x4 {
  margin-left: 32px;
}

@media screen and (max-width: 960px) {
  .ml-x4 {
    margin-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x4 {
    margin-left: 16px;
  }
}

.pt-x4 {
  padding-top: 32px;
}

@media screen and (max-width: 960px) {
  .pt-x4 {
    padding-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x4 {
    padding-top: 16px;
  }
}

.pr-x4 {
  padding-right: 32px;
}

@media screen and (max-width: 960px) {
  .pr-x4 {
    padding-right: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x4 {
    padding-right: 16px;
  }
}

.pb-x4 {
  padding-bottom: 32px;
}

@media screen and (max-width: 960px) {
  .pb-x4 {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x4 {
    padding-bottom: 16px;
  }
}

.pl-x4 {
  padding-left: 32px;
}

@media screen and (max-width: 960px) {
  .pl-x4 {
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x4 {
    padding-left: 16px;
  }
}

.mr-x5 {
  margin-right: 40px;
}

@media screen and (max-width: 960px) {
  .mr-x5 {
    margin-right: 30px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x5 {
    margin-right: 20px;
  }
}

.mb-x5 {
  margin-bottom: 40px;
}

@media screen and (max-width: 960px) {
  .mb-x5 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x5 {
    margin-bottom: 20px;
  }
}

.ml-x5 {
  margin-left: 40px;
}

@media screen and (max-width: 960px) {
  .ml-x5 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x5 {
    margin-left: 20px;
  }
}

.pt-x5 {
  padding-top: 40px;
}

@media screen and (max-width: 960px) {
  .pt-x5 {
    padding-top: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x5 {
    padding-top: 20px;
  }
}

.pr-x5 {
  padding-right: 40px;
}

@media screen and (max-width: 960px) {
  .pr-x5 {
    padding-right: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x5 {
    padding-right: 20px;
  }
}

.pb-x5 {
  padding-bottom: 40px;
}

@media screen and (max-width: 960px) {
  .pb-x5 {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x5 {
    padding-bottom: 20px;
  }
}

.pl-x5 {
  padding-left: 40px;
}

@media screen and (max-width: 960px) {
  .pl-x5 {
    padding-left: 30px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x5 {
    padding-left: 20px;
  }
}

.mr-x6 {
  margin-right: 48px;
}

@media screen and (max-width: 960px) {
  .mr-x6 {
    margin-right: 36px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x6 {
    margin-right: 24px;
  }
}

.mb-x6 {
  margin-bottom: 48px;
}

@media screen and (max-width: 960px) {
  .mb-x6 {
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x6 {
    margin-bottom: 24px;
  }
}

.ml-x6 {
  margin-left: 48px;
}

@media screen and (max-width: 960px) {
  .ml-x6 {
    margin-left: 36px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x6 {
    margin-left: 24px;
  }
}

.pt-x6 {
  padding-top: 48px;
}

@media screen and (max-width: 960px) {
  .pt-x6 {
    padding-top: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x6 {
    padding-top: 24px;
  }
}

.pr-x6 {
  padding-right: 48px;
}

@media screen and (max-width: 960px) {
  .pr-x6 {
    padding-right: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x6 {
    padding-right: 24px;
  }
}

.pb-x6 {
  padding-bottom: 48px;
}

@media screen and (max-width: 960px) {
  .pb-x6 {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x6 {
    padding-bottom: 24px;
  }
}

.pl-x6 {
  padding-left: 48px;
}

@media screen and (max-width: 960px) {
  .pl-x6 {
    padding-left: 36px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x6 {
    padding-left: 24px;
  }
}

.mr-x7 {
  margin-right: 56px;
}

@media screen and (max-width: 960px) {
  .mr-x7 {
    margin-right: 42px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x7 {
    margin-right: 28px;
  }
}

.mb-x7 {
  margin-bottom: 56px;
}

@media screen and (max-width: 960px) {
  .mb-x7 {
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x7 {
    margin-bottom: 28px;
  }
}

.ml-x7 {
  margin-left: 56px;
}

@media screen and (max-width: 960px) {
  .ml-x7 {
    margin-left: 42px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x7 {
    margin-left: 28px;
  }
}

.pt-x7 {
  padding-top: 56px;
}

@media screen and (max-width: 960px) {
  .pt-x7 {
    padding-top: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x7 {
    padding-top: 28px;
  }
}

.pr-x7 {
  padding-right: 56px;
}

@media screen and (max-width: 960px) {
  .pr-x7 {
    padding-right: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x7 {
    padding-right: 28px;
  }
}

.pb-x7 {
  padding-bottom: 56px;
}

@media screen and (max-width: 960px) {
  .pb-x7 {
    padding-bottom: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x7 {
    padding-bottom: 28px;
  }
}

.pl-x7 {
  padding-left: 56px;
}

@media screen and (max-width: 960px) {
  .pl-x7 {
    padding-left: 42px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x7 {
    padding-left: 28px;
  }
}

.mr-x8 {
  margin-right: 64px;
}

@media screen and (max-width: 960px) {
  .mr-x8 {
    margin-right: 48px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x8 {
    margin-right: 32px;
  }
}

.mb-x8 {
  margin-bottom: 64px;
}

@media screen and (max-width: 960px) {
  .mb-x8 {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x8 {
    margin-bottom: 32px;
  }
}

.ml-x8 {
  margin-left: 64px;
}

@media screen and (max-width: 960px) {
  .ml-x8 {
    margin-left: 48px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x8 {
    margin-left: 32px;
  }
}

.pt-x8 {
  padding-top: 64px;
}

@media screen and (max-width: 960px) {
  .pt-x8 {
    padding-top: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x8 {
    padding-top: 32px;
  }
}

.pr-x8 {
  padding-right: 64px;
}

@media screen and (max-width: 960px) {
  .pr-x8 {
    padding-right: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x8 {
    padding-right: 32px;
  }
}

.pb-x8 {
  padding-bottom: 64px;
}

@media screen and (max-width: 960px) {
  .pb-x8 {
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x8 {
    padding-bottom: 32px;
  }
}

.pl-x8 {
  padding-left: 64px;
}

@media screen and (max-width: 960px) {
  .pl-x8 {
    padding-left: 48px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x8 {
    padding-left: 32px;
  }
}

.mr-x9 {
  margin-right: 72px;
}

@media screen and (max-width: 960px) {
  .mr-x9 {
    margin-right: 54px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x9 {
    margin-right: 36px;
  }
}

.mb-x9 {
  margin-bottom: 72px;
}

@media screen and (max-width: 960px) {
  .mb-x9 {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x9 {
    margin-bottom: 36px;
  }
}

.ml-x9 {
  margin-left: 72px;
}

@media screen and (max-width: 960px) {
  .ml-x9 {
    margin-left: 54px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x9 {
    margin-left: 36px;
  }
}

.pt-x9 {
  padding-top: 72px;
}

@media screen and (max-width: 960px) {
  .pt-x9 {
    padding-top: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x9 {
    padding-top: 36px;
  }
}

.pr-x9 {
  padding-right: 72px;
}

@media screen and (max-width: 960px) {
  .pr-x9 {
    padding-right: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x9 {
    padding-right: 36px;
  }
}

.pb-x9 {
  padding-bottom: 72px;
}

@media screen and (max-width: 960px) {
  .pb-x9 {
    padding-bottom: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x9 {
    padding-bottom: 36px;
  }
}

.pl-x9 {
  padding-left: 72px;
}

@media screen and (max-width: 960px) {
  .pl-x9 {
    padding-left: 54px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x9 {
    padding-left: 36px;
  }
}

.mr-x10 {
  margin-right: 80px;
}

@media screen and (max-width: 960px) {
  .mr-x10 {
    margin-right: 60px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x10 {
    margin-right: 40px;
  }
}

.mb-x10 {
  margin-bottom: 80px;
}

@media screen and (max-width: 960px) {
  .mb-x10 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x10 {
    margin-bottom: 40px;
  }
}

.ml-x10 {
  margin-left: 80px;
}

@media screen and (max-width: 960px) {
  .ml-x10 {
    margin-left: 60px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x10 {
    margin-left: 40px;
  }
}

.pt-x10 {
  padding-top: 80px;
}

@media screen and (max-width: 960px) {
  .pt-x10 {
    padding-top: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x10 {
    padding-top: 40px;
  }
}

.pr-x10 {
  padding-right: 80px;
}

@media screen and (max-width: 960px) {
  .pr-x10 {
    padding-right: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x10 {
    padding-right: 40px;
  }
}

.pb-x10 {
  padding-bottom: 80px;
}

@media screen and (max-width: 960px) {
  .pb-x10 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x10 {
    padding-bottom: 40px;
  }
}

.pl-x10 {
  padding-left: 80px;
}

@media screen and (max-width: 960px) {
  .pl-x10 {
    padding-left: 60px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x10 {
    padding-left: 40px;
  }
}

.mr-x11 {
  margin-right: 88px;
}

@media screen and (max-width: 960px) {
  .mr-x11 {
    margin-right: 66px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x11 {
    margin-right: 44px;
  }
}

.mb-x11 {
  margin-bottom: 88px;
}

@media screen and (max-width: 960px) {
  .mb-x11 {
    margin-bottom: 66px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x11 {
    margin-bottom: 44px;
  }
}

.ml-x11 {
  margin-left: 88px;
}

@media screen and (max-width: 960px) {
  .ml-x11 {
    margin-left: 66px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x11 {
    margin-left: 44px;
  }
}

.pt-x11 {
  padding-top: 88px;
}

@media screen and (max-width: 960px) {
  .pt-x11 {
    padding-top: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x11 {
    padding-top: 44px;
  }
}

.pr-x11 {
  padding-right: 88px;
}

@media screen and (max-width: 960px) {
  .pr-x11 {
    padding-right: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x11 {
    padding-right: 44px;
  }
}

.pb-x11 {
  padding-bottom: 88px;
}

@media screen and (max-width: 960px) {
  .pb-x11 {
    padding-bottom: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x11 {
    padding-bottom: 44px;
  }
}

.pl-x11 {
  padding-left: 88px;
}

@media screen and (max-width: 960px) {
  .pl-x11 {
    padding-left: 66px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x11 {
    padding-left: 44px;
  }
}

.mr-x12 {
  margin-right: 96px;
}

@media screen and (max-width: 960px) {
  .mr-x12 {
    margin-right: 72px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x12 {
    margin-right: 48px;
  }
}

.mb-x12 {
  margin-bottom: 96px;
}

@media screen and (max-width: 960px) {
  .mb-x12 {
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x12 {
    margin-bottom: 48px;
  }
}

.ml-x12 {
  margin-left: 96px;
}

@media screen and (max-width: 960px) {
  .ml-x12 {
    margin-left: 72px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x12 {
    margin-left: 48px;
  }
}

.pt-x12 {
  padding-top: 96px;
}

@media screen and (max-width: 960px) {
  .pt-x12 {
    padding-top: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x12 {
    padding-top: 48px;
  }
}

.pr-x12 {
  padding-right: 96px;
}

@media screen and (max-width: 960px) {
  .pr-x12 {
    padding-right: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x12 {
    padding-right: 48px;
  }
}

.pb-x12 {
  padding-bottom: 96px;
}

@media screen and (max-width: 960px) {
  .pb-x12 {
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x12 {
    padding-bottom: 48px;
  }
}

.pl-x12 {
  padding-left: 96px;
}

@media screen and (max-width: 960px) {
  .pl-x12 {
    padding-left: 72px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x12 {
    padding-left: 48px;
  }
}

.mr-x13 {
  margin-right: 104px;
}

@media screen and (max-width: 960px) {
  .mr-x13 {
    margin-right: 78px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x13 {
    margin-right: 52px;
  }
}

.mb-x13 {
  margin-bottom: 104px;
}

@media screen and (max-width: 960px) {
  .mb-x13 {
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x13 {
    margin-bottom: 52px;
  }
}

.ml-x13 {
  margin-left: 104px;
}

@media screen and (max-width: 960px) {
  .ml-x13 {
    margin-left: 78px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x13 {
    margin-left: 52px;
  }
}

.pt-x13 {
  padding-top: 104px;
}

@media screen and (max-width: 960px) {
  .pt-x13 {
    padding-top: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x13 {
    padding-top: 52px;
  }
}

.pr-x13 {
  padding-right: 104px;
}

@media screen and (max-width: 960px) {
  .pr-x13 {
    padding-right: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x13 {
    padding-right: 52px;
  }
}

.pb-x13 {
  padding-bottom: 104px;
}

@media screen and (max-width: 960px) {
  .pb-x13 {
    padding-bottom: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x13 {
    padding-bottom: 52px;
  }
}

.pl-x13 {
  padding-left: 104px;
}

@media screen and (max-width: 960px) {
  .pl-x13 {
    padding-left: 78px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x13 {
    padding-left: 52px;
  }
}

.mr-x14 {
  margin-right: 112px;
}

@media screen and (max-width: 960px) {
  .mr-x14 {
    margin-right: 84px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x14 {
    margin-right: 56px;
  }
}

.mb-x14 {
  margin-bottom: 112px;
}

@media screen and (max-width: 960px) {
  .mb-x14 {
    margin-bottom: 84px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x14 {
    margin-bottom: 56px;
  }
}

.ml-x14 {
  margin-left: 112px;
}

@media screen and (max-width: 960px) {
  .ml-x14 {
    margin-left: 84px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x14 {
    margin-left: 56px;
  }
}

.pt-x14 {
  padding-top: 112px;
}

@media screen and (max-width: 960px) {
  .pt-x14 {
    padding-top: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x14 {
    padding-top: 56px;
  }
}

.pr-x14 {
  padding-right: 112px;
}

@media screen and (max-width: 960px) {
  .pr-x14 {
    padding-right: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x14 {
    padding-right: 56px;
  }
}

.pb-x14 {
  padding-bottom: 112px;
}

@media screen and (max-width: 960px) {
  .pb-x14 {
    padding-bottom: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x14 {
    padding-bottom: 56px;
  }
}

.pl-x14 {
  padding-left: 112px;
}

@media screen and (max-width: 960px) {
  .pl-x14 {
    padding-left: 84px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x14 {
    padding-left: 56px;
  }
}

.mr-x15 {
  margin-right: 120px;
}

@media screen and (max-width: 960px) {
  .mr-x15 {
    margin-right: 90px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x15 {
    margin-right: 60px;
  }
}

.mb-x15 {
  margin-bottom: 120px;
}

@media screen and (max-width: 960px) {
  .mb-x15 {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x15 {
    margin-bottom: 60px;
  }
}

.ml-x15 {
  margin-left: 120px;
}

@media screen and (max-width: 960px) {
  .ml-x15 {
    margin-left: 90px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x15 {
    margin-left: 60px;
  }
}

.pt-x15 {
  padding-top: 120px;
}

@media screen and (max-width: 960px) {
  .pt-x15 {
    padding-top: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x15 {
    padding-top: 60px;
  }
}

.pr-x15 {
  padding-right: 120px;
}

@media screen and (max-width: 960px) {
  .pr-x15 {
    padding-right: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x15 {
    padding-right: 60px;
  }
}

.pb-x15 {
  padding-bottom: 120px;
}

@media screen and (max-width: 960px) {
  .pb-x15 {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x15 {
    padding-bottom: 60px;
  }
}

.pl-x15 {
  padding-left: 120px;
}

@media screen and (max-width: 960px) {
  .pl-x15 {
    padding-left: 90px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x15 {
    padding-left: 60px;
  }
}

.mr-x16 {
  margin-right: 128px;
}

@media screen and (max-width: 960px) {
  .mr-x16 {
    margin-right: 96px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x16 {
    margin-right: 64px;
  }
}

.mb-x16 {
  margin-bottom: 128px;
}

@media screen and (max-width: 960px) {
  .mb-x16 {
    margin-bottom: 96px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x16 {
    margin-bottom: 64px;
  }
}

.ml-x16 {
  margin-left: 128px;
}

@media screen and (max-width: 960px) {
  .ml-x16 {
    margin-left: 96px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x16 {
    margin-left: 64px;
  }
}

.pt-x16 {
  padding-top: 128px;
}

@media screen and (max-width: 960px) {
  .pt-x16 {
    padding-top: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x16 {
    padding-top: 64px;
  }
}

.pr-x16 {
  padding-right: 128px;
}

@media screen and (max-width: 960px) {
  .pr-x16 {
    padding-right: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x16 {
    padding-right: 64px;
  }
}

.pb-x16 {
  padding-bottom: 128px;
}

@media screen and (max-width: 960px) {
  .pb-x16 {
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x16 {
    padding-bottom: 64px;
  }
}

.pl-x16 {
  padding-left: 128px;
}

@media screen and (max-width: 960px) {
  .pl-x16 {
    padding-left: 96px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x16 {
    padding-left: 64px;
  }
}

.mr-x17 {
  margin-right: 136px;
}

@media screen and (max-width: 960px) {
  .mr-x17 {
    margin-right: 102px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x17 {
    margin-right: 68px;
  }
}

.mb-x17 {
  margin-bottom: 136px;
}

@media screen and (max-width: 960px) {
  .mb-x17 {
    margin-bottom: 102px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x17 {
    margin-bottom: 68px;
  }
}

.ml-x17 {
  margin-left: 136px;
}

@media screen and (max-width: 960px) {
  .ml-x17 {
    margin-left: 102px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x17 {
    margin-left: 68px;
  }
}

.pt-x17 {
  padding-top: 136px;
}

@media screen and (max-width: 960px) {
  .pt-x17 {
    padding-top: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x17 {
    padding-top: 68px;
  }
}

.pr-x17 {
  padding-right: 136px;
}

@media screen and (max-width: 960px) {
  .pr-x17 {
    padding-right: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x17 {
    padding-right: 68px;
  }
}

.pb-x17 {
  padding-bottom: 136px;
}

@media screen and (max-width: 960px) {
  .pb-x17 {
    padding-bottom: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x17 {
    padding-bottom: 68px;
  }
}

.pl-x17 {
  padding-left: 136px;
}

@media screen and (max-width: 960px) {
  .pl-x17 {
    padding-left: 102px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x17 {
    padding-left: 68px;
  }
}

.mr-x18 {
  margin-right: 144px;
}

@media screen and (max-width: 960px) {
  .mr-x18 {
    margin-right: 108px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x18 {
    margin-right: 72px;
  }
}

.mb-x18 {
  margin-bottom: 144px;
}

@media screen and (max-width: 960px) {
  .mb-x18 {
    margin-bottom: 108px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x18 {
    margin-bottom: 72px;
  }
}

.ml-x18 {
  margin-left: 144px;
}

@media screen and (max-width: 960px) {
  .ml-x18 {
    margin-left: 108px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x18 {
    margin-left: 72px;
  }
}

.pt-x18 {
  padding-top: 144px;
}

@media screen and (max-width: 960px) {
  .pt-x18 {
    padding-top: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x18 {
    padding-top: 72px;
  }
}

.pr-x18 {
  padding-right: 144px;
}

@media screen and (max-width: 960px) {
  .pr-x18 {
    padding-right: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x18 {
    padding-right: 72px;
  }
}

.pb-x18 {
  padding-bottom: 144px;
}

@media screen and (max-width: 960px) {
  .pb-x18 {
    padding-bottom: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x18 {
    padding-bottom: 72px;
  }
}

.pl-x18 {
  padding-left: 144px;
}

@media screen and (max-width: 960px) {
  .pl-x18 {
    padding-left: 108px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x18 {
    padding-left: 72px;
  }
}

.mr-x19 {
  margin-right: 152px;
}

@media screen and (max-width: 960px) {
  .mr-x19 {
    margin-right: 114px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x19 {
    margin-right: 76px;
  }
}

.mb-x19 {
  margin-bottom: 152px;
}

@media screen and (max-width: 960px) {
  .mb-x19 {
    margin-bottom: 114px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x19 {
    margin-bottom: 76px;
  }
}

.ml-x19 {
  margin-left: 152px;
}

@media screen and (max-width: 960px) {
  .ml-x19 {
    margin-left: 114px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x19 {
    margin-left: 76px;
  }
}

.pt-x19 {
  padding-top: 152px;
}

@media screen and (max-width: 960px) {
  .pt-x19 {
    padding-top: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x19 {
    padding-top: 76px;
  }
}

.pr-x19 {
  padding-right: 152px;
}

@media screen and (max-width: 960px) {
  .pr-x19 {
    padding-right: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x19 {
    padding-right: 76px;
  }
}

.pb-x19 {
  padding-bottom: 152px;
}

@media screen and (max-width: 960px) {
  .pb-x19 {
    padding-bottom: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x19 {
    padding-bottom: 76px;
  }
}

.pl-x19 {
  padding-left: 152px;
}

@media screen and (max-width: 960px) {
  .pl-x19 {
    padding-left: 114px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x19 {
    padding-left: 76px;
  }
}

.mr-x20 {
  margin-right: 160px;
}

@media screen and (max-width: 960px) {
  .mr-x20 {
    margin-right: 120px;
  }
}

@media screen and (max-width: 520px) {
  .mr-x20 {
    margin-right: 80px;
  }
}

.mb-x20 {
  margin-bottom: 160px;
}

@media screen and (max-width: 960px) {
  .mb-x20 {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 520px) {
  .mb-x20 {
    margin-bottom: 80px;
  }
}

.ml-x20 {
  margin-left: 160px;
}

@media screen and (max-width: 960px) {
  .ml-x20 {
    margin-left: 120px;
  }
}

@media screen and (max-width: 520px) {
  .ml-x20 {
    margin-left: 80px;
  }
}

.pt-x20 {
  padding-top: 160px;
}

@media screen and (max-width: 960px) {
  .pt-x20 {
    padding-top: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pt-x20 {
    padding-top: 80px;
  }
}

.pr-x20 {
  padding-right: 160px;
}

@media screen and (max-width: 960px) {
  .pr-x20 {
    padding-right: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pr-x20 {
    padding-right: 80px;
  }
}

.pb-x20 {
  padding-bottom: 160px;
}

@media screen and (max-width: 960px) {
  .pb-x20 {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pb-x20 {
    padding-bottom: 80px;
  }
}

.pl-x20 {
  padding-left: 160px;
}

@media screen and (max-width: 960px) {
  .pl-x20 {
    padding-left: 120px;
  }
}

@media screen and (max-width: 520px) {
  .pl-x20 {
    padding-left: 80px;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  display: block;
  position: relative;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.slick-track:before, .slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.header {
  width: 100%;
  min-width: 1000px;
  max-width: 1282px;
  margin: 0 auto;
}

.header .header-iframe {
  width: 100%;
  min-width: 1000px;
  height: 200px;
}

.header-info {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 40px;
}

@media screen and (max-width: 1280px) {
  .header-info {
    padding: 0 32px;
  }
}

.header-info__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.header-info .header-primary {
  padding-top: 16px;
  width: 300px;
}

.header-info .header-secondary {
  max-width: calc(100% - 300px);
}

.header-info .sitettl {
  margin-bottom: 8px;
  font-size: 1.2rem;
}

.header-info .header-logo {
  max-width: 270px;
}

@media screen and (max-width: 1280px) {
  .header-info .header-logo {
    max-width: 200px;
  }
}

.header-info .header-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.header-info .header-menu .header-hotline {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-right: 24px;
  padding-top: 16px;
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-hotline {
    gap: 12px;
    margin-right: 18px;
  }
}

.header-info .header-menu .header-hotline .header-hotline-txt {
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}

@media screen and (max-width: 1280px) {
  .header-info .header-menu .header-hotline .header-hotline-txt {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-hotline .header-hotline-txt {
    letter-spacing: 0;
  }
}

.header-info .header-menu .header-hotline .header-hotline-num {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.2;
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 1280px) {
  .header-info .header-menu .header-hotline .header-hotline-num {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-hotline .header-hotline-num {
    font-size: 2rem;
    letter-spacing: 0;
  }
}

.header-info .header-menu .header-hotline .header-hotline-num .ico-tel {
  width: 42px;
  height: 28px;
  fill: #42A33E;
}

@media screen and (max-width: 1280px) {
  .header-info .header-menu .header-hotline .header-hotline-num .ico-tel {
    width: 36px;
    height: 26px;
  }
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-hotline .header-hotline-num .ico-tel {
    width: 32px;
    height: 24px;
  }
}

.header-info .header-menu .header-hotline .header-hotline-time {
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-hotline .header-hotline-time {
    font-size: 1.4rem;
  }
}

.header-info .header-menu .header-hotline .header-hotline-note {
  font-size: 1.2rem;
  font-weight: bold;
  text-indent: -0.5em;
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-hotline .header-hotline-note {
    font-size: 1rem;
    text-indent: 0;
  }
}

.header-info .header-menu .header-menu-registry,
.header-info .header-menu .header-menu-cart {
  width: 88px;
}

@media screen and (max-width: 1280px) {
  .header-info .header-menu .header-menu-registry,
  .header-info .header-menu .header-menu-cart {
    width: 78px;
  }
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-menu-registry,
  .header-info .header-menu .header-menu-cart {
    width: 68px;
  }
}

.header-info .header-menu .header-menu-registry a,
.header-info .header-menu .header-menu-cart a {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  border-radius: 0 0 2px 2px;
  color: #ffffff;
  font-size: 1.4rem;
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-menu-registry a,
  .header-info .header-menu .header-menu-cart a {
    font-size: 1.2rem;
  }
}

.header-info .header-menu .header-menu-registry a::after,
.header-info .header-menu .header-menu-cart a::after {
  position: absolute;
  right: 4px;
  bottom: 4px;
  content: '';
  width: 8px;
  height: 8px;
  background: linear-gradient(to bottom right, transparent 50%, #ffffff 50%);
}

.header-info .header-menu .header-menu-registry {
  margin-right: 8px;
}

.header-info .header-menu .header-menu-registry a {
  background: #73B41B;
}

.header-info .header-menu .header-menu-registry a .ico-registry {
  width: 32px;
  height: 32px;
  fill: #ffffff;
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-menu-registry a .ico-registry {
    width: 24px;
    height: 24px;
  }
}

.header-info .header-menu .header-menu-cart a {
  background: #c80952;
}

.header-info .header-menu .header-menu-cart a .ico-cart {
  width: 32px;
  height: 32px;
  fill: #ffffff;
}

@media screen and (max-width: 1024px) {
  .header-info .header-menu .header-menu-cart a .ico-cart {
    width: 26px;
    height: 26px;
  }
}

.header-info .header-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-right: solid 2px #bbb;
}

.header-info .header-nav__item {
  border-left: solid 2px #bbb;
}

.header-info .header-nav__item a {
  display: block;
  padding: 0 40px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
}

@media screen and (max-width: 1280px) {
  .header-info .header-nav__item a {
    padding: 0 32px;
  }
}

@media screen and (max-width: 1024px) {
  .header-info .header-nav__item a {
    padding: 0 24px;
  }
}

.header-info .header-nav__item em {
  display: block;
  font-size: 1.6rem;
  font-weight: normal;
  font-family: "Inter", sans-serif;
}

.footer {
  width: 100%;
  min-width: 1000px;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 16px;
}

@media screen and (max-width: 1280px) {
  .footer {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .footer {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.footer .footer-iframe {
  width: 100%;
  height: 1180px;
}

@media screen and (max-width: 1280px) {
  .footer .footer-iframe {
    height: 1330px;
  }
}

@media screen and (max-width: 1024px) {
  .footer .footer-iframe {
    height: 1300px;
  }
}

.footer-info {
  margin-bottom: 48px;
}

.footer-info .footer-info-unit {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

@media screen and (max-width: 1280px) {
  .footer-info .footer-info-unit {
    gap: 24px;
  }
}

.footer-info .footer-info-unit__col {
  width: calc(33.33% - (80px / 3));
}

@media screen and (max-width: 1280px) {
  .footer-info .footer-info-unit__col {
    width: calc(33.33% - (48px / 3));
  }
}

.footer-info .footer-info-sect:not(:nth-last-of-type(1)) {
  margin-bottom: 16px;
}

.footer-info .footer-info-ttl {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: solid 3px #f4e9c5;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4;
}

.footer-info .footer-info-subttl {
  position: relative;
  margin-bottom: 8px;
  font-weight: bold;
}

.footer-info .footer-info-subttl::before {
  content: '●';
}

.footer-info .footer-info-subttl span {
  font-size: 1.4rem;
}

.footer-info .footer-info-link a {
  color: #73B41B;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
}

.footer-info .footer-info-link a:hover {
  text-decoration: none;
}

.footer-info .footer-info-credit {
  max-width: 252px;
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
}

.footer-nav__item {
  position: relative;
  padding: 0 8px;
  font-size: 1.4rem;
  font-weight: bold;
}

.footer-nav__item:not(:nth-last-of-type(1))::after {
  content: '';
  position: absolute;
  top: 5px;
  right: 0;
  width: 1px;
  height: 12px;
  background: #333333;
}

.footer-nav__item a:hover {
  color: #73B41B;
  text-decoration: underline;
}

.copy {
  font-size: 1rem;
  text-align: center;
}

.side-iframe {
  width: 100%;
  height: 600px;
}

.side-menu {
  width: 200px;
}

.side-menu .side-menu-list {
  margin-bottom: 12px;
  padding: 12px 16px;
  border: solid 1px #f4e9c5;
  border-radius: 2px;
}

.side-menu .side-menu-list__item:not(:nth-last-of-type(1)) {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: dotted 1px #f4e9c5;
}

.side-menu .side-menu-bnr__item {
  margin-bottom: 12px;
  border: solid 1px #eeeeee;
}

.side-menu .side-menu-hotline {
  margin-bottom: 12px;
  padding: 12px 16px;
  background: #c80952;
  border-radius: 2px;
  letter-spacing: 0.05em;
}

.side-menu .side-menu-hotline dt {
  margin-bottom: 6px;
  padding-bottom: 4px;
  border-bottom: solid 2px #ffffff;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.side-menu .side-menu-hotline dd {
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  font-feature-settings: "palt";
}

.side-menu .side-menu-hotline dd em {
  display: block;
  font-size: 2rem;
  font-family: "Inter", sans-serif;
}

.side-menu .side-menu-hotline dd span {
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
}

.side-menu .side-menu-mobile {
  border: solid 1px #73B41B;
  border-radius: 2px;
}

.side-menu .side-menu-mobile dt {
  padding: 12px 16px;
  background: #73B41B;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
}

.side-menu .side-menu-mobile dd {
  padding: 16px;
}

.side-menu .side-menu-mobile__img {
  max-width: 120px;
  margin: 0 auto 12px;
}

.side-menu .side-menu-mobile__txt {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}

.notfound-txt {
  margin-bottom: calc(8px * 3);
  text-align: center;
}

@media screen and (max-width: 960px) {
  .notfound-txt {
    margin-bottom: calc((8px * 3) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .notfound-txt {
    margin-bottom: calc((8px * 3) * 0.5);
  }
}

.notfound-message {
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: calc(8px * 4);
  padding: calc(8px * 4);
  border: solid 1px #cccccc;
}

@media screen and (max-width: 960px) {
  .notfound-message {
    margin-bottom: calc((8px * 4) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .notfound-message {
    margin-bottom: calc((8px * 4) * 0.5);
  }
}

@media screen and (max-width: 960px) {
  .notfound-message {
    padding: calc((8px * 4) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .notfound-message {
    padding: calc((8px * 4) * 0.5);
  }
}

.content-wrap {
  overflow: hidden;
}

.content-all {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  min-width: 1064px;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-right: 40px;
  padding-left: 40px;
  gap: 40px;
}

@media screen and (max-width: 1280px) {
  .content-all {
    padding-right: 32px;
    padding-left: 32px;
  }
}

.content-all .content-main {
  width: calc(100% - 240px);
}

.content-all .content-side {
  width: 200px;
}

.hero {
  margin-bottom: 64px;
}

.hero .hero-iframe {
  width: 100%;
  aspect-ratio: 4 / 1.8;
}

.hero-slider {
  width: calc(100% - 160px);
  margin: 0 auto;
}

.hero-slider .slick-list {
  overflow: visible;
}

.hero-slider .slick-list .slick-slide {
  padding: 0 10px;
}

.hero-slider .slick-prev,
.hero-slider .slick-next {
  opacity: 0.8;
  font-size: 0;
  width: 32px;
  height: 64px;
  background: #333333;
  transform: translateY(-50%);
  cursor: pointer;
}

.hero-slider .slick-prev::before,
.hero-slider .slick-next::before {
  content: '';
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: #ffffff;
  transform: translateY(-50%) rotateZ(-45deg);
}

.hero-slider .slick-prev {
  position: absolute;
  top: 50%;
  left: -80px;
  z-index: 99;
  border-radius: 0 32px 32px 0;
}

.hero-slider .slick-prev::before {
  position: absolute;
  top: 50%;
  left: 8px;
  border-width: 2px 0 0 2px;
  transform: translateY(-50%) rotateZ(-45deg);
}

.hero-slider .slick-next {
  position: absolute;
  top: 50%;
  right: -80px;
  z-index: 99;
  border-radius: 32px 0 0 32px;
}

.hero-slider .slick-next::before {
  position: absolute;
  top: 50%;
  right: 8px;
  border-width: 2px 2px 0 0;
  transform: translateY(-50%) rotateZ(45deg);
}

.breadcrumb {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: calc(8px * 10);
}

@media screen and (max-width: 1280px) {
  .breadcrumb {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .breadcrumb {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 520px) {
  .breadcrumb {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media screen and (max-width: 960px) {
  .breadcrumb {
    margin-bottom: calc((8px * 10) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .breadcrumb {
    margin-bottom: calc((8px * 10) * 0.5);
  }
}

.breadcrumb__item {
  display: inline;
}

.breadcrumb__item:not(:nth-last-of-type(1)) {
  position: relative;
}

.breadcrumb__item:not(:nth-last-of-type(1))::after {
  content: '>';
  padding: 0 4px 0 8px;
}

.breadcrumb__item a {
  text-decoration: underline;
}

.breadcrumb__item a:hover {
  text-decoration: none;
}

.headline {
  position: relative;
  margin-bottom: 32px;
  font-size: 2.4rem;
  padding: 10px 16px 12px 24px;
  background: #73B41B;
  border-radius: 2px;
  color: #ffffff;
  line-height: 1.4;
  font-weight: bold;
}

.headline::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 12px;
  width: 4px;
  height: calc(100% - 24px);
  background: #ffffff;
  transform: translateY(-50%);
}

.ttl-l {
  margin-bottom: 8px;
  font-size: 2rem;
  font-weight: bold;
}

.ttl-m {
  margin-bottom: 4px;
  font-size: 1.6rem;
  font-weight: bold;
}

.txt.--center {
  text-align: center;
}

.txt.--right {
  text-align: right;
}

.txt a {
  color: #73B41B;
  text-decoration: underline;
}

.txt a:hover {
  text-decoration: none;
}

.lead-txt {
  font-size: 2rem;
}

.lead-txt.--center {
  text-align: center;
}

.lead-txt.--right {
  text-align: right;
}

.link-txt {
  color: #73B41B;
  text-decoration: underline;
}

.link-txt:hover {
  text-decoration: none;
}

.note-txt {
  margin-left: 1em;
  font-size: 1.4rem;
  text-indent: -1em;
}

.note-txt::before {
  content: '※';
}

.fc-red {
  color: #c80952;
}

.fc-blu {
  color: #30669B;
}

.fc-grn {
  color: #73B41B;
}

.fc-beg {
  color: #f4e9c5;
}

.fc-brn {
  color: #a52a2a;
}

.fc-pnk {
  color: #ffc0cb;
}

.fc-ppl {
  color: #c54ea0;
}

.fc-vio {
  color: #704cbc;
}

.fc-blk {
  color: #333333;
}

.fc-wht {
  color: #ffffff;
}

.fc-l-gry {
  color: #eeeeee;
}

.fc-gry {
  color: #cccccc;
}

.fc-d-gry {
  color: #999999;
}

.list__item {
  margin-left: 1em;
  text-indent: -1em;
}

.list__item:not(:nth-last-of-type(1)) {
  margin-bottom: 4px;
}

.list__item::before {
  content: '・';
}

.num-list {
  counter-reset: list-num;
}

.num-list__item {
  margin-left: 1em;
  text-indent: -1em;
}

.num-list__item::before {
  counter-increment: list-num;
  content: counter(list-num) ".";
}

.note-list__item {
  margin-left: 1em;
  font-size: 1.4rem;
  text-indent: -1em;
}

.note-list__item::before {
  content: '※';
  color: #c80952;
}

.btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.btn--left {
  justify-content: flex-start;
}

.btn--right {
  justify-content: flex-end;
}

.btn a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 64px;
  background: #333333;
  border-radius: solid 1px #333333;
  border-radius: 4px;
  color: #ffffff;
  line-height: 1.4;
}

.btn a:hover {
  background: #ffffff;
  color: #333333;
}

.btn a.bc-red {
  background: #c80952;
}

.btn a.bc-red:hover {
  background: #ffffff;
  color: #c80952;
}

.btn a.bc-blu {
  background: #30669B;
}

.btn a.bc-blu:hover {
  background: #ffffff;
  color: #30669B;
}

.btn a.bc-grn {
  background: #73B41B;
}

.btn a.bc-grn:hover {
  background: #ffffff;
  color: #73B41B;
}

.btn a.bc-beg {
  background: #f4e9c5;
}

.btn a.bc-beg:hover {
  background: #ffffff;
  color: #f4e9c5;
}

.btn a.bc-brn {
  background: #a52a2a;
}

.btn a.bc-brn:hover {
  background: #ffffff;
  color: #a52a2a;
}

.btn a.bc-pnk {
  background: #ffc0cb;
}

.btn a.bc-pnk:hover {
  background: #ffffff;
  color: #ffc0cb;
}

.btn a.bc-ppl {
  background: #c54ea0;
}

.btn a.bc-ppl:hover {
  background: #ffffff;
  color: #c54ea0;
}

.btn a.bc-vio {
  background: #704cbc;
}

.btn a.bc-vio:hover {
  background: #ffffff;
  color: #704cbc;
}

.btn a.bc-blk {
  background: #333333;
}

.btn a.bc-blk:hover {
  background: #ffffff;
  color: #333333;
}

.btn a.bc-wht {
  background: #ffffff;
}

.btn a.bc-wht:hover {
  background: #ffffff;
  color: #ffffff;
}

.btn a.bc-l-gry {
  background: #eeeeee;
}

.btn a.bc-l-gry:hover {
  background: #ffffff;
  color: #eeeeee;
}

.btn a.bc-gry {
  background: #cccccc;
}

.btn a.bc-gry:hover {
  background: #ffffff;
  color: #cccccc;
}

.btn a.bc-d-gry {
  background: #999999;
}

.btn a.bc-d-gry:hover {
  background: #ffffff;
  color: #999999;
}

.tbl table {
  width: 100%;
  border-top: solid 1px #cccccc;
  border-left: solid 1px #cccccc;
}

.tbl table thead tr th {
  padding: 16px;
  background: #999999;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  color: #333333;
}

.tbl table tbody tr th,
.tbl table tbody tr td {
  padding: 8px 12px;
  border-right: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
}

.tbl table tbody tr th {
  width: 30%;
  background: #eeeeee;
  text-align: left;
}

.tbl table tbody tr td {
  width: 70%;
}

.frame {
  padding: 16px;
  border: solid 1px #cccccc;
}

.separate {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #cccccc;
}
