//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// フッター
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .footer {
    width: 100%;
    min-width: 1000px;
    @include wrap(1280);
    padding-bottom: 16px;
    .footer-iframe {
      width: 100%;
      height: 1180px;
      @include break(xl) {
        height: 1330px;
      }
      @include break(l) {
        height: 1300px;
      }
    }
  }

  .footer-info {
    margin-bottom: 48px;
    .footer-info-unit {
      @include flex();
      gap: 40px;
      @include break(xl) {
        gap: 24px;
      }
      &__col {
        width: calc(33.33% - (80px / 3));
        @include break(xl) {
          width: calc(33.33% - (48px / 3));
        }
      }
    }
    .footer-info-sect:not(:nth-last-of-type(1)) {
      margin-bottom: 16px;
    }
    .footer-info-ttl {
      margin-bottom: 16px;
      padding-bottom: 8px;
      border-bottom: solid 3px $beg;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.4
    }
    .footer-info-subttl {
      position: relative;
      margin-bottom: 8px;
      font-weight: bold;
      &::before {
        content: '●';
      }
      span {
        font-size: $fs-s;
      }
    }
    .footer-info-link {
      a {
        color: $grn;
        font-size: $fs-s;
        font-weight: bold;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .footer-info-credit {
      max-width: 252px;
    }
  }//.footer-info

  .footer-nav {
    @include flex(center);
    margin-bottom: 24px;
    &__item {
      position: relative;
      padding: 0 8px;
      font-size: $fs-s;
      font-weight: bold;
      &:not(:nth-last-of-type(1))::after {
        content: '';
        @include pos($t:5px,$r:0);
        width: 1px;
        height: 12px;
        background: $blk;
      }
      a {
        &:hover {
          color: $grn;
          text-decoration: underline;
        }
      }
    }
  }//.footer-nav

  .copy {
    font-size: $fs-xxs;
    text-align: center;
  }//.copy