//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 404ページ
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .notfound-txt {
    @include mb(x3);
    text-align: center;
  }//.notfound-txt
  .notfound-message {
    max-width: 640px;
    margin: 0 auto;
    @include mb(x4);
    @include p(x4);
    border: solid 1px $gry;
  }//.notfound-message