//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 初期設定
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//------------------------------//
// 基本設定
//------------------------------//
  html,
  body {
    width: 100%;
    height: 100%;
    color: $blk;
    background: $tra;
  }
  html {
    font-size: 62.5%;
  }
  body {
    overflow-x: auto;
    font-size: $fs-m;
    font-family: $ff-base;
    line-height: 1.6;
    letter-spacing: 0.05em;
    font-feature-settings: "palt";
    @include break(s) {
      letter-spacing: 0;
    }
  }
  a {
    color: $blk;
    text-decoration: none;
    overflow-wrap: break-word;
    transition: 0.3s ease-out;
    &:visited {
      color: $blk;
    }
    &:hover {
      color: $blk;
    }
    &:active {
      color: $blk;
    }
    &[href*="tel:"] {
      text-decoration: none;
      pointer-events: none;
      cursor: default;
      @include break(m) {
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    &:not(.no-fit) {    
      width: 100%;
    }
  }


//------------------------------//
// 端末ごとの表示・非表示の設定
//------------------------------//
  // 1280px以上
  @media screen and (min-width: $xl + 1) {
    .view-xl { display: none !important; }
  }
  // 1280px以下
  @media screen and (max-width: $xl) {
    .none-xl { display: none !important; }
  }
  // 1024px以上
  @media screen and (min-width: $l + 1) {
    .view-l { display: none !important; }
  }
  // 1024px以下
  @media screen and (max-width: $l) {
    .none-l { display: none !important; }
  }
  // 960px以上
  @media screen and (min-width: $m + 1) {
    .view-m { display: none !important; }
  }
  // 960px以下
  @media screen and (max-width: $m) {
    .none-m { display: none !important; }
  }
  // 768px以上
  @media screen and (min-width: $s + 1) {
    .view-s { display: none !important; }
  }
  // 768px以下
  @media screen and (max-width: $s) {
    .none-s { display: none !important; }
  }
  // 520px以上
  @media screen and (min-width: $xs + 1) {
    .view-xs { display: none !important; }
  }
  // 520px以下
  @media screen and (max-width: $xs) {
    .none-xs { display: none !important; }
  }


//------------------------------//
// サイト幅
//------------------------------//
  .wrap {
    @include wrap(1280);
  }
  .inner {
    @include wrap;
  }


//------------------------------//
// 余白
//------------------------------//
  // セクションごとの余白
  .mb-sect {
    @include mb(x10); // 80px
  }
  .mb-subsect {
    @include mb(x6); // 48px
  }

  @for $i from 1 through 20 {
    .mr-x#{$i} {
      margin-right: $space * $i;
      @include break(m) {
        margin-right: $space * $i * 0.75;
      }
      @include break(xs) {
        margin-right: $space * $i * 0.5;
      }
    }
    .mb-x#{$i} {
      margin-bottom: $space * $i;
      @include break(m) {
        margin-bottom: $space * $i * 0.75;
      }
      @include break(xs) {
        margin-bottom: $space * $i * 0.5;
      }
    }
    .ml-x#{$i} {
      margin-left: $space * $i;
      @include break(m) {
        margin-left: $space * $i * 0.75;
      }
      @include break(xs) {
        margin-left: $space * $i * 0.5;
      }
    }
    .pt-x#{$i} {
      padding-top: $space * $i;
      @include break(m) {
        padding-top: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-top: $space * $i * 0.5;
      }
    }
    .pr-x#{$i} {
      padding-right: $space * $i;
      @include break(m) {
        padding-right: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-right: $space * $i * 0.5;
      }
    }
    .pb-x#{$i} {
      padding-bottom: $space * $i;
      @include break(m) {
        padding-bottom: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-bottom: $space * $i * 0.5;
      }
    }
    .pl-x#{$i} {
      padding-left: $space * $i;
      @include break(m) {
        padding-left: $space * $i * 0.75;
      }
      @include break(xs) {
        padding-left: $space * $i * 0.5;
      }
    }
  }


//------------------------------//
// スライダー
//------------------------------//
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    display: block;
    position: relative;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  .slick-arrow.slick-hidden {
    display: none;
  }