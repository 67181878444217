//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// ヘッダー
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .header {
    width: 100%;
    min-width: 1000px;
    max-width: 1282px;
    margin: 0 auto;
    // @include wrap(1280);
    .header-iframe {
      width: 100%;
      min-width: 1000px;
      height: 200px;
    }
  }
  .header-info {
    width: 100%;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 40px;
    @include break(xl) {
      padding: 0 32px;
    }
    &__wrap {
      @include flex(space-between);
      margin: 0 auto;
    }
    .header-primary {
      padding-top: 16px;
      width: 300px;
    }
    .header-secondary {
      max-width: calc(100% - 300px);
    }
    .sitettl {
      margin-bottom: 8px;
      font-size: $fs-xs;
    }
    .header-logo {
      max-width: 270px;
      @include break(xl) {
        max-width: 200px;
      }
    }
    .header-menu {
      @include flex(right);
      margin-bottom: 16px;
      .header-hotline {
        @include flex();
        gap: 16px;
        margin-right: 24px;
        padding-top: 16px;
        @include break(l) {
          gap: 12px;
          margin-right: 18px;
        }
        .header-hotline-txt {
          font-weight: bold;
          font-family: $ff-min;
          @include break(xl) {
            font-size: $fs-s;
          }
          @include break(l) {
            letter-spacing: 0;
          }
        }
        .header-hotline-num {
          @include flex('',center);
          gap: 6px;
          font-size: $fs-xxl;
          font-weight: bold;
          line-height: 1.2;
          font-family: $ff-en;
          @include break(xl) {
            font-size: 2.2rem;
          }
          @include break(l) {
            font-size: $fs-l;
            letter-spacing: 0;
          }
          .ico-tel {
            width: 42px;
            height: 28px;
            fill: $d-grn;
            @include break(xl) {
              width: 36px;
              height: 26px;
            }
            @include break(l) {
              width: 32px;
              height: 24px;
            }
          }
        }
        .header-hotline-time {
          font-weight: bold;
          @include break(l) {
            font-size: $fs-s;
          }
        }
        .header-hotline-note {
          font-size: $fs-xs;
          font-weight: bold;
          text-indent: -0.5em;
          @include break(l) {
            font-size: $fs-xxs;
            text-indent: 0;
          }
        }
      }
      .header-menu-registry,
      .header-menu-cart {
        width: 88px;
        @include break(xl) {
          width: 78px;
        }
        @include break(l) {
          width: 68px;
        }
        a {
          @include flex(right,center,column);
          gap: 8px;
          position: relative;
          width: 100%;
          height: 100%;
          padding-bottom: 20px;
          border-radius: 0 0 2px 2px;
          color: $wht;
          font-size: $fs-s;
          @include break(l) {
            font-size: $fs-xs;
          }
          &::after {
            @include pos($r:4px,$b:4px);
            content: '';
            width: 8px;
            height: 8px;
            background: linear-gradient( to bottom right, $tra 50%, $wht 50%);
          }
        }
      }
      .header-menu-registry {
        margin-right: 8px;
        a {
          background: $grn;
          .ico-registry {
            width: 32px;
            height: 32px;
            fill: $wht;
            @include break(l) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .header-menu-cart {
        a {
          background: $red;
          .ico-cart {
            width: 32px;
            height: 32px;
            fill: $wht;
            @include break(l) {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
    .header-nav {
      @include flex(right);
      border-right: solid 2px #bbb;
      &__item {
        border-left: solid 2px #bbb;
        a {
          display: block;
          padding: 0 40px;
          font-size: $fs-xxs;
          font-weight: bold;
          text-align: center;
          line-height: 1.2;
          @include break(xl) {
            padding: 0 32px;
          }
          @include break(l) {
            padding: 0 24px;
          }
        }
        em {
          display: block;
          font-size: $fs-m;
          font-weight: normal;
          font-family: $ff-en;
        }
      }
    }
  }