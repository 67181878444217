//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 1.クラス名の付け方について
// BEM記法はクラス名が長くなりやすいため単語は極力短縮してください。
// ※ただし、短くしすぎて他者に伝わらない場合があるので注意してください。
//
// 例：よく使う単語
// 見出し　　　→　[NG] title    [OK] ttl
// テキスト　　→　[NG] text     [OK] txt
// ボタン　　　→　[NG] button   [OK] btn
//
// 2.接頭辞について
// 下記のものについては接頭辞を使用して他のクラスと差別化してください。
//
// 例：
// 状態を表すためのクラス　　→　is-current,is-hidden 等
// スクリプト用のクラス　　　→　js-tab,js-acd 等
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// パーツ読み込み
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  @import "_variable.scss"; // 変数、mixin
  @import "_reset.scss"; // リセット
  @import "_default.scss"; // 初期設定
  @import '_header.scss'; // ヘッダー
  @import '_footer.scss'; // フッター
  @import '_side.scss'; // サイドバー
  @import '_notfound.scss'; // 404ページ

  

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 汎用パーツ
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .content-wrap {
    overflow: hidden;
  }
  .content-all {
    @include flex();
    max-width: 1280px;
    min-width: 1064px;
    margin: 0 auto;
    margin-bottom: 120px;
    padding-right: 40px;
    padding-left: 40px;
    gap: 40px;
    @include break(xl) {
      padding-right: 32px;
      padding-left: 32px;
    }
    .content-main {
      width: calc(100% - 240px);
    }
    .content-side {
      width: 200px;
    }
  }

//--------------------------------------------------//
// メインビジュアル（共通）
//--------------------------------------------------//
  .hero {
    margin-bottom: 64px;
    .hero-iframe {
      width: 100%;
      aspect-ratio: 4 / 1.8;
    }
  }
  .hero-slider {
    width: calc(100% - 160px);
    margin: 0 auto;
    .slick-list {
      overflow: visible;
      .slick-slide {
        padding: 0 10px;
      }
    }
    .slick-prev,
    .slick-next {
      opacity: 0.8;
      font-size: 0;
      width: 32px;
      height: 64px;
      background: $blk;
      transform: translateY(-50%);
      cursor: pointer;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-style: solid;
        border-color: $wht;
        transform: translateY(-50%) rotateZ(-45deg);
      }
    }
    .slick-prev {
      @include pos($t:50%,$l:-80px,$z:99);
      border-radius: 0 32px 32px 0;
      &::before {
        @include pos($t:50%,$l:8px);
        border-width: 2px 0 0 2px;
        transform: translateY(-50%) rotateZ(-45deg);
      }
    }//.slick-prev
    .slick-next {
      @include pos($t:50%,$r:-80px,$z:99);
      border-radius: 32px 0 0 32px ;
      &::before {
        @include pos($t:50%,$r:8px);
        border-width: 2px 2px 0 0;
        transform: translateY(-50%) rotateZ(45deg);
      }
    }//.slick-next
  }

//--------------------------------------------------//
// パンくず
//--------------------------------------------------//
  .breadcrumb {
    @include wrap(1280);
    @include mb(x10);
    &__item {
      display: inline;
      &:not(:nth-last-of-type(1)) {
        position: relative;
        &::after {
          content: '>';
          padding: 0 4px 0 8px;
        }
      }
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }//.breadcrumb__item
  }//.breadcrumb

//--------------------------------------------------//
// 見出し（h1〜h6タグ）
//--------------------------------------------------//
  // ページ見出し
  .headline {
    position: relative;
    margin-bottom: 32px;
    font-size: $fs-xl;
    padding: 10px 16px 12px 24px;
    background: $grn;
    border-radius: 2px;
    color: $wht;
    line-height: 1.4;
    font-weight: bold;
    &::after {
      content: '';
      @include pos($t:50%,$l:12px);
      width: 4px;
      height: calc(100% - 24px);
      background: $wht;
      transform: translateY(-50%);
    }
  }//.headline
  // 大見出し
  .ttl-l {
    margin-bottom: 8px;
    font-size: $fs-l;
    font-weight: bold;
  }//.ttl-l
  // 中見出し
  .ttl-m {
    margin-bottom: 4px;
    font-size: $fs-m;
    font-weight: bold;
  }//.ttl-m

//--------------------------------------------------//
// 段落（pタグ）
//--------------------------------------------------//
  // 通常文章
  .txt {
    &.--center {
      text-align: center;
    }
    &.--right {
      text-align: right;
    }
    a {
      color: $grn;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }//.txt

  // リード文章
  .lead-txt {
    font-size: $fs-l;
    &.--center {
      text-align: center;
    }
    &.--right {
      text-align: right;
    }
  }//.lead-txt

  // リンク付き文章
  .link-txt {
    color: $grn;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }//.link-txt

  // 注釈（※）付き文章
  .note-txt {
    margin-left: 1em;
    font-size: $fs-s;
    text-indent: -1em;
    &::before {
      content: '※';
    }
  }//.note-txt

  // フォントカラー
  @each $name, $color in $colors {
    .fc-#{$name} {
      color: $color;
    }
  }

//--------------------------------------------------//
// リスト（ul,olタグ）
//--------------------------------------------------//
  // 通常リスト
  .list {
    &__item {
      margin-left: 1em;
      text-indent: -1em;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 4px;
      }
      &::before {
        content: '・';
      }
    }//.list__item
  }//.list

  // 番号付きリスト
  .num-list {
    counter-reset: list-num;
    &__item {
      margin-left: 1em;
      text-indent: -1em;
      &::before {
        counter-increment: list-num;
        content: counter(list-num)'.';
      }
    }//.num-list__item
  }//.num-list

  // 注釈（※）付きリスト
  .note-list {
    &__item {
      margin-left: 1em;
      font-size: $fs-s;
      text-indent: -1em;
      &::before {
        content: '※';
        color: $red;
      }
    }//.note-list__item
  }//.note-list

//--------------------------------------------------//
// ボタン
//--------------------------------------------------//
  // 通常ボタン
  .btn {
    @include flex(center);
    gap: 24px;
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    a {
      @include flex(center,center);
      width: 100%;
      max-width: 300px;
      height: 64px;
      background: $blk;
      border-radius: solid 1px $blk;
      border-radius: 4px;
      color: $wht;
      line-height: 1.4;
      &:hover {
        background: $wht;
        color: $blk;
      }
      // ボタンカラー
      @each $name, $color in $colors {
        &.bc-#{$name} {
          background: $color;
          &:hover {
            background: $wht;
            color: $color;
          }
        }
      }
    }
  }//.btn

  // ページトップボタン
  // .pagetop {
  //   right: 20px;
  //   bottom: calc( 0px + env(safe-area-inset-bottom));
  //   width: 64px;
  //   height: 64px;
  //   cursor: pointer;
  // }//.pagetop

//--------------------------------------------------//
// テーブル（tableタグ）
//--------------------------------------------------//
  .tbl {
    table {
      width: 100%;
      border-top: solid 1px $gry;
      border-left: solid 1px $gry;
      thead {
        tr {
          th {
            padding: 16px;
            background: lighten($blk, 40%);
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
            color: $blk;
          }
        }
      }//thead
      tbody {
        tr {
          th,
          td {
            padding: 8px 12px;
            border-right: solid 1px $gry;
            border-bottom: solid 1px $gry;
          }
          th {
            width: 30%;
            background: $l-gry;
            text-align: left;
          }
          td {
            width: 70%;
          }
        }
      }//tbody
    }//table
  }//.tbl

//--------------------------------------------------//
// 枠
//--------------------------------------------------//
  .frame {
    padding: 16px;
    border: solid 1px $gry;
  }//.frame

//--------------------------------------------------//
// 区切り線
//--------------------------------------------------//
  .separate {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px $gry;
  }