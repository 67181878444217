//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// サイドバー
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  .side-iframe {
    width: 100%;
    height: 600px;
  }

  .side-menu {
    width: 200px;
    .side-menu-list {
      margin-bottom: 12px;
      padding: 12px 16px;
      border: solid 1px $beg;
      border-radius: 2px;
      &__item {
        &:not(:nth-last-of-type(1)) {
          margin-bottom: 10px;
          padding-bottom: 12px;
          border-bottom: dotted 1px $beg;
        }
      }
    }//.side-menu-list
    .side-menu-bnr {
      &__item {
        margin-bottom: 12px;
        border: solid 1px $l-gry;
      }
    }
    .side-menu-hotline {
      margin-bottom: 12px;
      padding: 12px 16px;
      background: $red;
      border-radius: 2px;
      letter-spacing: 0.05em;
      dt {
        margin-bottom: 6px;
        padding-bottom: 4px;
        border-bottom: solid 2px $wht;
        color: $wht;
        font-weight: bold;
        text-align: center;
      }
      dd {
        color: $wht;
        font-size: $fs-xxs;
        font-weight: bold;
        text-align: center;
        line-height: 1.3;
        font-feature-settings: "palt";
        em {
          display: block;
          font-size: $fs-l;
          font-family: $ff-en;
        }
        span {
          font-size: $fs-xs;
          font-family: $ff-en;
        }
      }
    }//.side-menu-hotline
    .side-menu-mobile {
      border: solid 1px $grn;
      border-radius: 2px;
      dt {
        padding: 12px 16px;
        background: $grn;
        color: $wht;
        font-weight: bold;
        line-height: 1.4;
        text-align: center;
      }
      dd {
        padding: 16px;
      }
      &__img {
        max-width: 120px;
        margin: 0 auto 12px;
      }
      &__txt {
        font-size: $fs-s;
        letter-spacing: 0.05em;
      }
    }//.side-menu-mobile
  }//.side-menu